import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Rating from '@material-ui/lab/Rating';
import Grid from '@material-ui/core/Grid';
import {red} from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import FilterHdrIcon from '@material-ui/icons/FilterHdr';
import {Route} from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  media: {
    height: 140,
  },
  avatar: {
    backgroundColor: red[500],
  },
});

export default function MediaCard(props) {
  const classes = useStyles();

  return (
    <Route
       render={({history}) => (
         <Card className={classes.root}
               onClick={() => {
                 history.push("/id/" + props.routeData['id']);
               }}>
           <CardActionArea>
             <CardMedia
               className={classes.media}
               image={props.routeData['imgURL']}
               title={props.routeData['id']}
             />
             <CardContent>
               <div>
                 <FilterHdrIcon style={{color: props.routeData['color'], marginRight: "0.25em"}} size="large"/>
                 <Typography gutterBottom variant="h4" component="h2" display="inline">
                   {props.routeData['id']}
                 </Typography>
               </div>
               <Rating defaultValue={props.routeData['starRating']} readOnly precision={0.1}></Rating>
               <Grid>
                 <Typography variant="subtitle1" color="textSecondary" component="span">
                   {props.routeData['area']}
                 </Typography>
               </Grid>
               <Grid>
                 <Typography variant="subtitle2" color="textSecondary" component="span"
                             style={{marginRight: "0.5em"}}>
                   {props.routeData['type']}
                 </Typography>
                 <Typography variant="subtitle2" color="textSecondary" component="span">
                   {props.routeData['grade']}
                 </Typography>
               </Grid>
             </CardContent>
           </CardActionArea>
           {/* <CardActions>
            <Button size="small" color="primary">
              <AddIcon /> Add to Projects
            </Button>
            <Button size="small" color="primary" disabled>
              View Stats
            </Button>
          </CardActions> */}
         </Card>
       )} />

  );
}
