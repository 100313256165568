import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Fab from '@material-ui/core/Fab';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import firebase from "../../Firebase";
import ImageUploader from './ImageUploader';
import getFullColorName from "../../funcs/ColorToFullColor";

const getRouteLevel = (levelStr, type) => {
  if (type === "Boulder")
    return parseFloat(levelStr.replace("V", ""));
  else if (type === "Top Rope")
    return parseFloat(levelStr.replace("5.", ""));
  return "Invalid";
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FABStyle = {
  margin: 0,
  top: 'auto',
  right: 20,
  bottom: 20,
  left: 'auto',
  position: 'fixed',
};

const textFieldStyle = {
  width: "75%"
};

export default function AddRoute(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [routeID, setRouteID] = React.useState(props.cell ? (props.cell['id']) : "");
  const [routeType, setRouteType] = React.useState(props.cell ? (props.cell['type']) : "Boulder");
  const [routeLevel, setRouteLevel] = React.useState(props.cell ? (props.cell['grade']) : "");
  const [routeArea, setRouteArea] = React.useState(props.cell ? (props.cell['area']) : "");
  const [routeImgUrl, setRouteImgUrl] = React.useState(props.cell ? (props.cell['imgURL']) : "");
  const [routeColor, setRouteColor] = React.useState(props.cell ? (props.cell['color']) : "none");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRouteID = (event) => {
    setRouteID(event.target.value);
  }

  const handleRouteLevel = (event) => {
    setRouteLevel(event.target.value);
  }

  const handleRouteArea = (event) => {
    setRouteArea(event.target.value);
  }

  const handleURLChanged = (url) => {
    setRouteImgUrl(url);
  }

  const handleRouteType = (event) => {
    setRouteType(event.target.value);
  }

  const handleRouteColor = (event) => {
    setRouteColor(event.target.value);
  }

  const handleSave = () => {
    let db = firebase.firestore();
    let data = {
      id: routeID.replace(" ", ""),
      type: routeType,
      level: routeLevel,
      area: routeArea,
      imgURL: routeImgUrl,
      status: "NEW",
      color: routeColor,
      active: true,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      edited: firebase.firestore.FieldValue.serverTimestamp()
    }
    if (data['id'] && data['type'] &&
      data['level'] && data['area'] &&
      data['imgURL'] && data['color'] !== "none") {
      db.collection('routes').doc(routeID).set(data).then(() => {
        handleClose();
      });
    } else {
      alert("Please fill out all fields");
    }
  }

  return (
    <div>
      {(props.type === "edit") && (
        <Button onClick={handleClickOpen} variant={"outlined"}>Edit</Button>
      )}
      {(props.type !== "edit") && (
        <Fab
          onClick={handleClickOpen}
          style={FABStyle}
          color="secondary">
          <AddIcon/>
        </Fab>
      )}
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar} color="primary">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon/>
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Add New Route
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSave}>
              <SaveIcon style={{marginRight: "0.5em"}}/> Save
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem>
            <ListItemText
              primary="Route ID"
              secondary={`/id/${routeID}`}/>
            <TextField
              required
              id="route-id"
              label="Route ID"
              value={routeID}
              onChange={handleRouteID}
              variant="outlined"
              style={textFieldStyle}
            />
          </ListItem>
          {(routeID !== "") && (
            <div>
              <Divider/>
              <ListItem>
                <ListItemText
                  primary="Route Type"
                  secondary={`${routeType}`}/>
                <Select
                  id="select-route-type"
                  variant="outlined"
                  value={routeType}
                  onChange={handleRouteType}
                  style={textFieldStyle}
                >
                  <MenuItem value={"Boulder"}>Boulder</MenuItem>
                  <MenuItem value={"Top Rope"}>Top Rope</MenuItem>
                </Select>
              </ListItem>
              <Divider/>
              <ListItem>
                <ListItemText
                  primary="Route Rating"
                  secondary={getRouteLevel(routeLevel, routeType)}/>
                <TextField
                  required
                  id="route-rating"
                  label="Route Rating"
                  value={routeLevel}
                  onChange={handleRouteLevel}
                  variant="outlined"
                  style={textFieldStyle}
                />
              </ListItem>
              <Divider/>
              <ListItem>
                <ListItemText
                  primary="Route Color"
                  secondary={"Selected Color: " + routeColor}
                  style={{color: routeColor}}/>
                <Select
                  id="select-route-color"
                  variant="outlined"
                  value={routeColor}
                  onChange={handleRouteColor}
                  style={textFieldStyle}
                >
                  <MenuItem selected value={"none"}>None</MenuItem>
                  <MenuItem value={"purple"}>{getFullColorName("purple")}</MenuItem>
                  <MenuItem value={"green"}>{getFullColorName("green")}</MenuItem>
                  <MenuItem value={"greenyellow"}>{getFullColorName("greenyellow")}</MenuItem>
                  <MenuItem value={"yellow"}>{getFullColorName("yellow")}</MenuItem>
                  <MenuItem value={"red"}>{getFullColorName("red")}</MenuItem>
                  <MenuItem value={"navy"}>{getFullColorName("navy")}</MenuItem>
                  <MenuItem value={"blue"}>{getFullColorName("blue")}</MenuItem>
                  <MenuItem value={"lightblue"}>{getFullColorName("lightblue")}</MenuItem>
                  <MenuItem value={"orange"}>{getFullColorName("orange")}</MenuItem>
                  <MenuItem value={"lightgrey"}>{getFullColorName("lightgrey")}</MenuItem>
                  <MenuItem value={"black"}>{getFullColorName("black")}</MenuItem>
                  <MenuItem value={"beige"}>{getFullColorName("beige")}</MenuItem>
                  <MenuItem value={"hotpink"}>{getFullColorName("hotpink")}</MenuItem>
                </Select>
              </ListItem>
              <Divider/>
              <ListItem>
                <ListItemText
                  primary="Route Area"
                  secondary="Describe Route Area"/>
                <Select
                  id="select-route-area"
                  variant="outlined"
                  value={routeArea}
                  onChange={handleRouteArea}
                  style={textFieldStyle}
                >
                  <MenuItem selected value={"none"}>None</MenuItem>
                  <MenuItem value={"Tunnel"}>Tunnel</MenuItem>
                  <MenuItem value={"Mezz"}>Mezz</MenuItem>
                  <MenuItem value={"Cave"}>Cave</MenuItem>
                  <MenuItem value={"30"}>30</MenuItem>
                  <MenuItem value={"Book(Interior)"}>Book(Interior)</MenuItem>
                  <MenuItem value={"Book(S/W)"}>Book(S/W)</MenuItem>
                  <MenuItem value={"New"}>New</MenuItem>
                </Select>
              </ListItem>
              <Divider/>
              <ListItem>
                <ListItemText
                  primary="Route Photo"
                  secondary="Upload Route Photo"/>
                <ImageUploader name={routeID} handleURLChanged={handleURLChanged}/>
              </ListItem>
            </div>
          )}
        </List>
      </Dialog>
    </div>
  );
}
