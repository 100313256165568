import React from "react";
import {Box, CircularProgress, Grid, MenuItem, Paper, Select, Typography} from '@material-ui/core';
import firebase from "../Firebase";
import ShowProjectsChart from "../charts/ShowProjectsChart";
import ShowProgressChart from "../charts/ShowProgressChart";

class ViewProfile extends React.Component {
  constructor(props) {
    super(props);
    this.userId = props.match.params.userId;
    this.user = null;
    this.state = {};
    this.projectData = null;
    this.selectedRoute = "";
    this.routeTimeData = null;

    this.handleSelectedRoute = (selectedRoute, userId) => {
      firebase.firestore().collection('users').doc(userId)
        .collection('feedback').doc(selectedRoute).collection('history').get().then((docs) => {
        let output = [];
        output.push(['Date', 'Attempts', 'Sends']);
        docs.forEach((doc) => {
          var temp = [];
          temp.push(doc.id);
          temp.push(doc.data()['attempts']);
          temp.push(doc.data()['sends']);
          output.push(temp);
        });
        this.routeTimeData = output;
        this.setState({routeTimeData: this.routeTimeData});
      });
    }

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (!this.userId)
          this.userId = user.uid;
        this.user = user;
        this.setState({
          user: this.user,
          userId: this.userId
        })
        firebase.analytics().logEvent('Profile Viewed', {
          profileId: this.userId,
        })
        firebase.firestore().collection('users').doc(user.uid).collection('feedback')
          .where('isProject', '==', true).onSnapshot((docs) => {
          console.log("SNAPSHOT PROFILE");
          let projectsOutput = [];
          let ratingOutput = [];
          projectsOutput.push(['Project', 'Attempts', 'Sends']);
          ratingOutput.push(['Rating', 'Attempts', 'Sends']);
          docs.forEach((doc) => {
            var temp = [];
            if (doc.data()['isProject']) {
              temp.push(doc.id);
              temp.push(doc.data()['attempts']);
              temp.push(doc.data()['sends']);
              projectsOutput.push(temp);
            }
          });
          this.projectData = projectsOutput;
          this.setState({projectData: this.projectData});
          if (this.projectData[1]) {
            this.selectedRoute = this.projectData[1][0];
            this.handleSelectedRoute(this.selectedRoute, user.uid);
          }
        });

      }
    });
  }

  render() {
    return (
      <div>
        {(this.user) && (
          <Grid Container>
            <Paper component={Box} p={4} mb={2}>
              <Grid container alignItems="flex-start" justify="center" direction="column">
                <Grid item>
                  <Typography variant="h3">Profile</Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper component={Box} p={4} mb={2}>
              <Typography variant="h4" component={Box} mb={2}>Statistics</Typography>
              {(this.projectData && this.projectData.length > 1) && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h5">Sends and Attempts</Typography>
                    {this.projectData && (
                      <ShowProjectsChart type="ColumnChart" data={this.projectData} title='Current Projects'/>
                    )}
                    {!this.projectData && (
                      <CircularProgress/>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" display="inline">Send and Attempts Over Time for </Typography>
                    {this.projectData && (
                      <Select
                        labelId="select-label"
                        id="simple-select"
                        value={this.selectedRoute}
                        onChange={(event) => {
                          this.selectedRoute = event.target.value;
                          this.setState({selectedRoute: this.selectedRoute});
                          this.handleSelectedRoute(event.target.value, this.user.uid);
                        }}
                      >
                        {this.projectData.map((item => {
                          if (item[0] !== "Projects") {
                            return (
                              <MenuItem value={item[0]}>{item[0]}</MenuItem>
                            )
                          } else {
                            return null;
                          }
                        }))}
                      </Select>
                    )}
                    {this.routeTimeData && (
                      <>
                        {this.routeTimeData.length > 1 && (
                          <ShowProgressChart type="AreaChart" data={this.routeTimeData}
                                             title={'Progess Over Time on ' + this.selectedRoute}/>
                        )}
                        {this.routeTimeData.length === 1 && (
                          <h2>No History</h2>
                        )}
                      </>
                    )}
                    {!this.routeTimeData && (
                      <CircularProgress/>
                    )}
                  </Grid>
                </>
              )}
              {(this.projectData && this.projectData.length === 1) && (
                <>
                  <h1>No Projects</h1>
                </>
              )}
            </Paper>

          </Grid>
        )}
        {(!this.user) && (
          <Grid container>
            <CircularProgress/>
          </Grid>
        )}
      </div>
    );
  }
}

export default ViewProfile;
