import React from 'react';
import { Chart } from "react-google-charts";
import {CircularProgress} from '@material-ui/core';
import { blue, green } from '@material-ui/core/colors';

class ShowProgressChart extends React.Component {
    constructor(props) {
        super(props);
        this.data = props.data;
        this.type = props.type;
        this.title = props.title;
    }

    componentWillUpdate (props) {
        this.data = props.data;
    }

    render() {
        return (
            <div style={{ display: 'flex', minWidth: 300, width: "100%" }}>
                {this.data.length !== 1 && (
                <Chart
                    width="100%"
                    height={400}
                    chartType={this.type}
                    loader={<CircularProgress />}
                    data={this.data}
                    options={{
                        title: this.title,
                        chartArea: { width: '75%' },
                        colors: [blue[800], green[600]],
                        hAxis: {
                            title: 'Date',
                            minValue: 0,
                        },
                        vAxis: {
                            title: 'Total',
                            minValue: 0,
                        },
                    }}
                    legendToggle
                />
                )}
                {this.data.length === 1 && this.type === "ColumnChart" (
                    <h2>No Data. Try adding projects. </h2>
                )}
            </div>
        );
    }
}

export default ShowProgressChart;