import React from "react";
import NavBar from './components/NavBar'
import SignIn from './components/SignIn';
import ViewClimbs from './components/ViewClimbs';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import ViewRoute from './components/ViewRoute';
import AdminPortal from './components/AdminPortal';
import ViewProfile from './components/ViewProfile';
import Container from '@material-ui/core/Container';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import firebase from './Firebase';

// function getParameterByName(name, url = window.location.href) {
//   name = name.replace(/[\[\]]/g, '\\$&');
//   var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
//       results = regex.exec(url);
//   if (!results) return null;
//   if (!results[2]) return '';
//   return decodeURIComponent(results[2].replace(/\+/g, ' '));
// }

const theme = createMuiTheme({
  palette: {
    primary: {
      main: grey[900],
    },
    secondary: {
      main: blue[200],
    },
  },
});

function App() {

  const [auth, setAuth] = React.useState(true);
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      firebase.analytics().setUserId(user.uid);
      if (!auth)
        setAuth(true);
      firebase.firestore().collection('users').doc(user.uid).set({
        phoneNumber: user.phoneNumber,
        uid: user.uid,
        loginType: "phone",
        loginPlatform: "web"
      });
    } else {
      setAuth(false);
    }
  });

  return (
    <div>
      {auth && (
          <Router basename="/">
            <Switch>
              <ThemeProvider theme={theme}>
                <NavBar />
                <Container style={{ marginTop: '72px' }}>
                  <Route path="/admin" component={AdminPortal} />
                  <Route path="/id/:routeID" component={ViewRoute} />
                  <Route path="/profile/:userId" component={ViewProfile} />
                  <Route path="/profile" component={ViewProfile} />
                  <Route path="/login" component={SignIn}/>
                  <Route exact path="/" component={ViewClimbs} />
                </Container>
              </ThemeProvider>
            </Switch>
        </Router>
      )}
      {!auth &&(
        <Router basename="/">
          <Switch>
            <ThemeProvider theme={theme}>
              <NavBar />
              <Container style={{ marginTop: '72px' }}>
                <Route path="/id/:routeID" component={ViewRoute} />
                <Route path="/login" component={SignIn}/>
                <Route exact path="/" component={ViewClimbs} />
              </Container>
            </ThemeProvider>
          </Switch>
        </Router>
      )}
    </div>

  );
}

export default App;
