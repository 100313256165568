import React from "react";
import {fade, withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ProjectDrawer from './ProjectDrawer';
import Button from '@material-ui/core/Button';
import firebase from '../Firebase';
import {Link, Route} from 'react-router-dom';
import logo from '../img/Knot-Logo.png';

const drawerWidth = 360;

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  profileIcon: {
    marginRight: theme.spacing(2),
    textAlign: 'right',
    alignSelf: 'stretch'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
});

class MenuAppBar extends React.Component {

  constructor(props) {
    super(props);
    this.drawerOpen = false;
    this.currentProjects = [];
    this.auth = false;
    this.anchorEl = null;
    this.openProfile = Boolean(this.anchorEl);
    this.currentProjects = [];
    this.routeSearch = '';

    const db = firebase.firestore();

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (!this.auth) {
          this.auth = user;
          this.setState({auth: user})
        }
        db.collection("users").doc(user.uid).collection("feedback").where('isProject', '==', true).onSnapshot((docs) => {
          var rows = [];
          this.currentProjects = [];
          var promises = [];
          docs.forEach((doc) => {
            var tempRow = {};
            tempRow['id'] = doc.id;
            tempRow['attempts'] = doc.data()['attempts'];
            tempRow['sends'] = doc.data()['sends'];
            promises.push(db.collection("routes").doc(doc.id).get().then((routeData) => {
              if (routeData.exists) {
                tempRow['grade'] = routeData.data()['level'];
                tempRow['area'] = routeData.data()['area'];
                tempRow['type'] = routeData.data()['type'];
                tempRow['color'] = routeData.data()['color'];
              }
              rows.push(tempRow);
              this.currentProjects.push(tempRow);
              this.setState({currentProjects: this.currentProjects});
            }));
          });
          Promise.all(promises).then(function () {
          });

        });
      } else {
        this.auth = false;
        this.setState({auth: false})
      }
    });

  }

  render() {
    const {classes} = this.props;

    // const handleChange = (event) => {
    //   setAuth(event.target.checked);
    // };

    const handleDrawerOpen = () => {
      this.drawerOpen = true;
      this.setState({drawerOpen: true});
    };

    const handleDrawerClose = () => {
      this.drawerOpen = false;
      this.setState({drawerOpen: false});
    };

    const handleMenu = (event) => {
      this.anchorEl = event.currentTarget;
      console.log(this.anchorEl);
      this.setState({anchorEl: this.anchorEl});
    };

    const handleClose = () => {
      this.anchorEl = null;
      this.setState({anchorEl: null});
    };

    const handleLogout = () => {
      firebase.auth().signOut();
    };

    return (
      <div className={classes.root}>
        <AppBar position="absolute">
          <Toolbar>
            {this.auth && (
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
                          onClick={handleDrawerOpen}>
                <MenuIcon/>
              </IconButton>
            )}
            <Route
              render={({history}) => (
                <>
                  <img src={logo} alt="Logo" height="24px" style={{paddingRight: "6px"}}
                       onClick={ () => { history.push("/"); } }/>
                  <Typography variant="h6" fontWeight="fontWeightBold" className={classes.title}
                              style={{color: "white", textDecoration: "none"}}
                              onClick={ () => { history.push("/"); } }>
                    The Knot
                  </Typography>
                </>
              )}
            />

            {!this.auth && (
              <div style={{marginLeft: 'auto'}}>
                <Button
                  size="small"
                  style={{color: "white"}}
                  onClick={() => {
                    window.location = "/login";
                  }
                  }>
                  Login
                </Button>
              </div>
            )}

            {this.auth && (
              <div style={{marginLeft: 'auto'}}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={(event) => {
                    window.location.href = "/profile";
                  }}
                  color="inherit"
                >
                  <AccountCircle/>
                </IconButton>
                <Button
                  size="small"
                  style={{color: "white"}}
                  onClick={handleLogout}>
                  Logout
                </Button>
                <Menu
                  id="menu-appbar"
                  anchorEl={this.anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={this.openProfile}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>

        <ProjectDrawer
          drawerOpen={this.drawerOpen}
          handleDrawerClose={handleDrawerClose}
          currentProjects={this.currentProjects}
        />

      </div>
    );
  }
}

export default withStyles(styles)(MenuAppBar);
