
export default function getFullColorName(color) {
    switch(color) {
        case 'purple':
            return "Purple";
        case 'green':
            return "Green";
        case 'lightgreen':
            return "Neon Green";
        case 'yellow':
            return "Yellow";
        case 'red':
            return "Red";
        case 'navy':
            return "Dark Blue";
        case 'blue':
            return "Blue";
        case 'lightblue':
            return "Light Blue";
        case 'black':
            return "Black";
        case 'beige':
            return "Beige";
        case 'lightgrey':
            return "White";
        case 'hotpink':
            return "Pink"
        case 'tan':
            return "Tan"
        case 'orange':
            return "Orange"
        default:
            return "none";
    }
}
