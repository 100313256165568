import * as React from 'react';
import {Box, Divider, Grid, IconButton, Paper, Slider, TextField, Typography} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Rating from '@material-ui/lab/Rating';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Input from '@material-ui/core/Input';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
// import { spacing } from '@material-ui/system';
import firebase from "../Firebase";
import parseRouteLevelString from "../funcs/parseRouteString";
import RatingComponent from "./render_components/RatingComponent";
import RouteInfoComponent from "./render_components/RouteInfoComponent";

const getRouteLevel = (levelStr, type) => {
  if (type === "Boulder")
    return parseFloat(levelStr.replace("V", ""));
  else if (type === "Top Rope")
    return parseFloat(levelStr.replace("5.", ""));
  return "Invalid";
}


function getSliderLimits(routeRating) {
  if (routeRating - 3 < 0) {
    return [0, routeRating + 3];
  }
  return [routeRating - 3, routeRating + 3];
}

const getRouteLevelString = (level, type) => {
  let output = "";
  if (type === "Boulder") {
    output = "V" + level;
  } else if (type === "Top Rope") {
    output = "5." + parseRouteLevelString(level);
  }
  return output;
}

async function updateFirestore(data, userId, selectedId, routeData = {}) {

  firebase.analytics().logEvent('Route Changed', {
    route_id: selectedId,
    user_id: userId
  });

  data['updated'] = firebase.firestore.FieldValue.serverTimestamp();
  firebase.firestore().collection("users").doc(userId)
    .collection("feedback").doc(selectedId)
    .set(data, {merge: true});
}

class ViewRoute extends React.Component {

  constructor(props) {
    super(props);
    this.db = firebase.firestore();
    console.log(props.match.params.routeID);
    this.selectedId = String(props.match.params.routeID).padStart(4, '0');
    this.routeData = null;
    this.user = null;
    this.userData = {};
    this.ratingValue = null;
    this.chipData = [
      {selected: false, label: 'SLOPEY', key: 0},
      {selected: false, label: 'JUGGY', key: 1},
      {selected: false, label: 'CRIMPY', key: 2},
      {selected: false, label: 'TWEAKY', key: 3},
      {selected: false, label: 'OUCH!', key: 4},
      {selected: false, label: 'REACHY', key: 5},
      {selected: false, label: 'SCRUNCHY', key: 6},
      {selected: false, label: 'SKETCHY', key: 7},
      {selected: false, label: 'TECHNICAL', key: 8},
      // Dynamic, Powerful, Classic, Balancy, Flowy, Overhung, Roof, Slab, Face, Pockety, Jumpy, NO!, YESS!
      {selected: false, label: 'DYNAMIC', key: 9},
      {selected: false, label: 'POWERFUL', key: 10},
      {selected: false, label: 'CLASSIC', key: 11},
      {selected: false, label: 'BALANCY', key: 12},
      {selected: false, label: 'FLOWY', key: 13},
      {selected: false, label: 'OVERHUNG', key: 14},
      {selected: false, label: 'ROOF', key: 15},
      {selected: false, label: 'SLAB', key: 16},
      {selected: false, label: 'FACE', key: 17},
      {selected: false, label: 'POCKETY', key: 18},
      {selected: false, label: 'JUMPY', key: 19},
      {selected: false, label: 'NO!', key: 20},
      {selected: false, label: 'YES!', key: 21},
      {selected: false, label: 'PUMPY', key: 22},

    ];
    this.routeRating = 3;
    this.userRouteRating = null;
    this.communityGrade = null;
    this.textFieldData = "";
    document.title = `The Knot - Route ${this.selectedId}`;

  };

  async componentDidMount() {
    firebase.auth().onAuthStateChanged((_user) => {
      if (this.user !== _user) {
        this.user = _user;
        this.db.collection("users").doc(_user.uid).collection("feedback").doc(this.selectedId).get().then((doc) => {
          if (!doc.exists) {
            doc.ref.set({
              viewed: firebase.firestore.FieldValue.serverTimestamp(),
              created: firebase.firestore.FieldValue.serverTimestamp(),
              updated: firebase.firestore.FieldValue.serverTimestamp(),
              isProject: false,
              sends: 0,
              attempts: 0
            });

          } else {
            if (doc.data()['sends'] && doc.data()['attempts']) {
              doc.ref.set({
                viewed: firebase.firestore.FieldValue.serverTimestamp()
              }, {merge: true});
            } else {
              doc.ref.set({
                viewed: firebase.firestore.FieldValue.serverTimestamp(),
                // sends: 0,
                // attempts: 0
              }, {merge: true});
            }

          }
        });
      } else {

      }
      if (_user) {
        firebase.analytics().logEvent('Route Visited', {
          route_id: this.props.match.params.routeID,
          user_id: _user.uid
        });

        this.db.collection("users").doc(_user.uid).collection("feedback").doc(this.selectedId).onSnapshot((userDoc) => {
          if (userDoc.exists) {
            this.userData = userDoc.data();
            if (this.userData['tags'])
              if (this.userData['tags'].length === this.chipData)
                this.chipData = this.userData['tags'];

            if (this.userData['starRating'])
              this.ratingValue = this.userData['starRating'];

            if (this.userData['userGrade'])
              this.userRouteRating = this.userData['userGrade'];

            if (this.userData['notes'])
              this.textFieldData = this.userData['notes'];


            this.setState({
              userData: this.userData,
              userRouteRating: this.userRouteRating,
              ratingValue: this.ratingValue,
              textFieldData: this.textFieldData,
            });
          }
        });
      }
    });

    this.db.collection("routes").doc(this.selectedId).onSnapshot((routeDoc) => {
      if (routeDoc.exists) {
        let dataTemp = routeDoc.data();
        if (!dataTemp['imgURL'])
          dataTemp['imgURL'] = "https://climbtheknot.com/wp-content/uploads/2020/08/Knot-Logo-W.png";
        this.routeData = dataTemp;
        this.routeRating = getRouteLevel(dataTemp['level'], dataTemp['type']);
        if (!this.userRouteRating)
          this.userRouteRating = this.routeRating;

        this.routeData['exists'] = true;
        this.setState({routeData: dataTemp});
      } else {
        this.routeData = {exists: false};
        this.setState({routeData: this.routeData});
      }
    });

  }

  render() {
    const handleSliderInputChange = (event, newValue) => {
      this.userRouteRating = newValue;
      this.setState({userRouteRating: this.userRouteRating});
    };

    const handleSliderNumberChange = (event) => {
      this.userRouteRating = event.target.value === '' ? '' : Number(event.target.value);
      this.setState({userRouteRating: this.userRouteRating});
    };

    return (
      <div>
        {this.routeData && this.routeData['exists'] && this.selectedId && (
          <Paper style={{padding: '2em'}}>
            <Grid container spacing={5} justify="center" direction="row" alignItems="center">


              <Grid item sm={12} md={5}>
                <img alt="Error Loading" src={this.routeData.imgURL}
                     width="100%"
                     style={{
                       width: "100%",
                       margin: '0 auto',
                       maxHeight: 400,
                       overflow: 'hidden',
                       objectFit: "contain",
                     }}/>
              </Grid>


              <Grid item container sm={12} md={7} justify={"flex-start"}>

                <RatingComponent routeData={this.routeData}/>

                {/* Show Projects Button */}
                {this.user && (
                  <Grid item xs={12} md={5} justify="space-around" alignItems="flex-start">
                    {(!this.userData['isProject']) && (
                      <IconButton
                        onClick={() => {
                          this.userData['isProject'] = true;
                          firebase.analytics().logEvent('Added Project', {
                            routeID: this.selectedId,
                            userID: this.user.uid
                          });
                          updateFirestore({isProject: true}, this.user.uid, this.selectedId, this.routeData);
                          this.setState({userData: this.userData});
                        }}>
                        <AddBoxIcon fontSize="large" color="secondary"></AddBoxIcon>
                        <Typography variant="caption" display="inline">Add to Projects</Typography>
                      </IconButton>
                    )}
                    {this.userData['isProject'] && (
                      <IconButton
                        onClick={() => {
                          this.userData['isProject'] = false;
                          firebase.analytics().logEvent('Removed Project', {
                            routeID: this.selectedId,
                            userID: this.user.uid
                          });
                          updateFirestore({isProject: false}, this.user.uid, this.selectedId, this.routeData);
                          this.setState({userData: this.userData});
                        }}>
                        <IndeterminateCheckBoxIcon fontSize="large" color="primary"></IndeterminateCheckBoxIcon>
                        <Typography variant="caption" display="inline">Remove from Projects</Typography>
                      </IconButton>
                    )}
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Divider/>
                </Grid>

                <RouteInfoComponent routeData={this.routeData}/>

                {/* Show Sends Attempts buttons */}
                {this.user && (
                  <Grid item container spacing={3}
                        direction="row"
                        justify="center"
                        alignItems="center">
                    { /* START ATTEMPTS */}
                    <Grid item xs={12} sm={6}>
                      <Box component="fieldset" mb={1} borderColor="transparent">
                        <Typography component="legend">Attempts</Typography>
                        {/*REMOVE*/}
                        <IconButton onClick={async () => {
                          if (this.userData['attempts']) {
                            if (this.userData['attempts'] > 0 && this.userData['attempts'] > this.userData['sends']) {
                              // updateFirestore({ attempts: attempts }, this.user.uid, this.selectedId, this.routeData);
                              firebase.firestore().collection("users").doc(this.user.uid).collection("feedback").doc(this.selectedId).set({
                                attempts: firebase.firestore.FieldValue.increment(-1),
                                updated: firebase.firestore.FieldValue.serverTimestamp()
                              }, {merge: true});
                              firebase.analytics().logEvent('Removed Attempt', {
                                routeID: this.selectedId,
                                attempts: this.userData['attempts'] - 1,
                                userID: this.user.uid
                              });
                            }
                          }
                        }}>
                          <RemoveCircleIcon fontSize="large" style={{color: "red"}}></RemoveCircleIcon>
                        </IconButton>
                        <Typography variant="h3"
                                    display="inline">{(this.userData && this.userData['attempts']) ? this.userData['attempts'] : "0"}</Typography>
                        {/*ADD*/}
                        <IconButton onClick={() => {
                          if (this.userData['attempts'] !== null) {
                            // updateFirestore({ attempts: attempts }, this.user.uid, this.selectedId, this.routeData);
                            firebase.firestore().collection(`/users/${this.user.uid}/feedback`).doc(this.selectedId).set({
                              attempts: firebase.firestore.FieldValue.increment(1),
                              updated: firebase.firestore.FieldValue.serverTimestamp()
                            }, {merge: true});
                            firebase.analytics().logEvent('Added Attempt', {
                              routeID: this.selectedId,
                              attempts: this.userData['attempts'] + 1,
                              userID: this.user.uid
                            });
                          }
                        }}>
                          <AddCircleIcon fontSize="large" style={{color: "green"}}></AddCircleIcon>
                        </IconButton>
                      </Box>
                    </Grid>
                    { /* END ATTEMPTS */}

                    { /* START SENDS */}
                    <Grid item xs={12} sm={6}>
                      <Box component="fieldset" mb={1} borderColor="transparent">
                        <Typography component="legend">Sends</Typography>
                        <IconButton onClick={() => {
                          if (this.userData['sends'] > 0) {
                            firebase.analytics().logEvent('Removed Send', {
                              routeID: this.selectedId,
                              sends: this.userData['sends'] - 1,
                              userID: this.user.uid
                            });
                            // updateFirestore({ sends: sends }, this.user.uid, this.selectedId, this.routeData);
                            firebase.firestore().collection(`/users/${this.user.uid}/feedback`).doc(this.selectedId).set({
                              sends: firebase.firestore.FieldValue.increment(-1),
                              updated: firebase.firestore.FieldValue.serverTimestamp()
                            }, {merge: true});
                          }
                        }}>
                          <RemoveCircleIcon fontSize="large" style={{color: "red"}}></RemoveCircleIcon>
                        </IconButton>
                        <Typography variant="h3"
                                    display="inline">{(this.userData && this.userData['sends']) ? this.userData['sends'] : "0"}</Typography>
                        <IconButton onClick={(target) => {
                          if (this.userData['sends'] < this.userData['attempts'] && this.userData['attempts'] >= 1) {
                            firebase.analytics().logEvent('Added Send', {
                              routeID: this.selectedId,
                              sends: this.userData['sends'] + 1,
                              userID: this.user.uid
                            });
                            // updateFirestore({ sends: sends }, this.user.uid, this.selectedId, this.routeData);
                            firebase.firestore().collection(`/users/${this.user.uid}/feedback`).doc(this.selectedId).set({
                              sends: firebase.firestore.FieldValue.increment(1),
                              updated: firebase.firestore.FieldValue.serverTimestamp()
                            }, {merge: true});
                          }
                        }}>
                          <AddCircleIcon fontSize="large" style={{color: "green"}}></AddCircleIcon>
                        </IconButton>
                      </Box>
                    </Grid>
                    { /* END SENDS */}
                  </Grid>
                )}


              </Grid>

              {this.user && (
                <Grid item sm={12} md={12}>
                <Box component="fieldset" mb={1} borderColor="transparent">
                  <Typography component="legend" display="block">Your Rating</Typography>
                  <Rating
                    name="star-rating"
                    size="large"
                    value={this.ratingValue}
                    onChange={(event, newValue) => {
                      this.ratingValue = newValue;
                      firebase.analytics().logEvent('Changed Rating', {
                        routeID: this.selectedId,
                        rating: this.ratingValue,
                        userID: this.user.uid
                      });
                      updateFirestore({starRating: newValue}, this.user.uid, this.selectedId, this.routeData);
                      this.setState({userData: this.userData, ratingValue: this.ratingValue})
                    }}
                  />
                </Box>
                {(this.routeRating !== null && this.userRouteRating !== null) && (
                  <Box component="fieldset" mb={1} borderColor="transparent">
                    <Typography id="grade-slider-label" component="legend">
                      Grade
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs>
                        <Slider
                          value={typeof this.userRouteRating === 'number' ? this.userRouteRating : 0}
                          step={0.25}
                          min={getSliderLimits(this.routeRating)[0]}
                          max={getSliderLimits(this.routeRating)[1]}
                          marks
                          onChange={handleSliderInputChange}
                          onChangeCommitted={(event, newValue) => {
                            firebase.analytics().logEvent('Changed Grade', {
                              routeID: this.selectedId,
                              grade: newValue,
                              userID: this.user.uid
                            });
                            updateFirestore({userGrade: newValue}, this.user.uid, this.selectedId, this.routeData);
                            this.setState({userData: this.userData, userRouteRating: this.userRouteRating})
                          }}
                          aria-labelledby="input-slider"
                        />
                      </Grid>
                      <Grid item>
                        <Input
                          style={{width: 54}}
                          value={this.userRouteRating}
                          margin="dense"
                          onChange={handleSliderNumberChange}
                          inputProps={{
                            step: 0.25,
                            min: getSliderLimits(this.routeRating)[0],
                            max: getSliderLimits(this.routeRating)[0],
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Typography display="inline">
                      Your Grade: <b>{this.userRouteRating}</b>,
                      Community Grade: <b>{
                      (this.routeData['userGradeCount'] && this.routeData['userGradeTotal'])
                        ? (this.routeData['userGradeTotal'] / this.routeData['userGradeCount']).toFixed(2)
                        : "No Grades"}</b></Typography>
                    <Typography variant="caption" display="inline"
                                style={{marginLeft: "0.2rem"}}>({this.routeData['userGradeCount'] ?? 0})</Typography>
                  </Box>
                )}

                <Box component="fieldset" mb={1} borderColor="transparent">
                  <Typography id="grade-slider" component="legend">
                    Tags
                  </Typography>
                  <Paper component="ul" elevation={0}
                         style={{
                           display: 'flex',
                           justifyContent: 'center',
                           flexWrap: 'wrap',
                           listStyle: 'none',
                           padding: '0.5rem',
                           margin: 0,
                         }}>
                    {this.chipData.map((data, index) => {
                      return (
                        <li key={data.key}>
                          <Chip
                            label={data.label}
                            color={data.selected ? "secondary" : "default"}
                            clickable
                            onClick={() => {
                              this.chipData[index]["selected"] = !this.chipData[index]["selected"];
                              if (this.chipData[index]["selected"])
                                firebase.analytics().logEvent('Selected Keyword', {
                                  routeID: this.selectedId,
                                  chipName: this.chipData[index]['label'],
                                  userID: this.user.uid
                                });
                              else
                                firebase.analytics().logEvent('Deselected Keyword', {
                                  routeID: this.selectedId,
                                  chipName: this.chipData[index]['label'],
                                  userID: this.user.uid
                                });
                              updateFirestore({tags: this.chipData}, this.user.uid, this.selectedId, this.routeData);
                              this.setState({userData: this.userData, chipData: this.chipData})
                            }}
                            style={{margin: '0.5rem'}}
                          />
                        </li>);
                    })}
                  </Paper>
                </Box>
                <Box component="fieldset" mb={1} borderColor="transparent">
                  <TextField
                    label="Notes"
                    multiline
                    rows={4}
                    variant="outlined"
                    style={{width: "100%"}}
                    value={this.textFieldData}
                    onChange={(event) => {
                      this.textFieldData = event.target.value;
                      this.setState({textFieldData: this.textFieldData});
                    }}
                    // onFocus={(event) => {
                    //     console.log(event);
                    // }}
                    onBlur={(event) => {
                      firebase.analytics().logEvent('Edited Notes', {
                        routeID: this.selectedId,
                        notes: event.target.value,
                        userID: this.user.uid
                      });
                      updateFirestore({notes: event.target.value}, this.user.uid, this.selectedId, this.routeData);
                    }}
                    // disabled
                  />
                </Box>
              </Grid>
              )}
            </Grid>
          </Paper>)}
        {this.routeData && !this.routeData['exists'] && (
          <Paper style={{padding: '2em'}}>
            <Typography variant="h4">Route ID not found.</Typography>
          </Paper>
        )}
        {!this.routeData && (
          <div style={{alignContent: "center"}}>
            <CircularProgress/>
          </div>
        )}
      </div>
    );
  }
}

export default ViewRoute;
