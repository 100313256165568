
const firebase = require('firebase/app');
require('firebase/firestore');
require('firebase/auth');
require('firebase/storage');
require('firebase/analytics');

// INIT FIREBASE
const firebaseConfig = {
  apiKey: "AIzaSyDiLRa4OZGkh6eP0Ln2IXim7rwAp6i1fY0",
  authDomain: "theknot-2c33b.firebaseapp.com",
  projectId: "theknot-2c33b",
  storageBucket: "theknot-2c33b.appspot.com",
  messagingSenderId: "430549368007",
  appId: "1:430549368007:web:314341a8302df34196d2e2",
  measurementId: "G-E8EVJLQ9M0"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;