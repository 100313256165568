import React from 'react';
import {DataGrid} from '@material-ui/data-grid';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import AddRoute from './admin_components/AddRoute';
import getFullColorName from '../funcs/ColorToFullColor';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import firebase from "../Firebase";
import {AppBar, Button, Tab, Tabs} from "@material-ui/core";
import ShowProjectsDist from "../charts/ShowRouteDist";

// Supporting Functions
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{width: "100%"}}
      {...other}
    >
      {value === index && (
        <Grid item xs={12} style={{width: "100%"}}>
          {children}
        </Grid>
      )}
    </div>
  );
}

function Statistics(props) {
  const {rowData} = props;

  // Get Active Routes
  let activeRoutes = [];
  let inactiveRoutes = [];
  for (let i = 0; i < rowData.length; i++) {
    let route = rowData[i];

    if (route.hasOwnProperty('active') && route['active']) {
      activeRoutes.push(route);
    } else {
      inactiveRoutes.push(route);
    }
  }

  // Get Grade Data
  const gradeData = [['Grade', 'Count']];
  let tempTypeArray = activeRoutes.map(a => a.grade);
  let tempTypeSet = Array.from(new Set(tempTypeArray)).sort();
  for (let i = 0; i < tempTypeSet.length; i++) {

    let count = 0;
    for (let j = 0; j < tempTypeArray.length; j++) {
      if (tempTypeArray[j] === tempTypeSet[i])
        count++;
    }

    gradeData.push([tempTypeSet[i], count]);
  }

  console.log(gradeData);

  return (
    <Grid container>
      <Grid item xs={6}>
        <h1>{activeRoutes.length} Active Routes </h1>
      </Grid>
      <Grid item xs={6}>
        <h2>{inactiveRoutes.length} Inactive Routes <br/> {rowData.length} Total Routes</h2>
      </Grid>
      <Grid item xs={12}>
        <ShowProjectsDist data={gradeData}/>
      </Grid>
    </Grid>
  );
}

// END: Supporting Functions

class AdminPortal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    document.title = `The Knot - Admin`;
    this.db = firebase.firestore();
    this.user = firebase.auth().currentUser;
    this.isAdmin = false;
    this.state = {};
    this.tabValue = 0;

    this.deleteRoute = (id) => {
      let r = window.confirm("Deleting is not recoverable.");
      if (r) {
        firebase.firestore().collection("routes").doc(id).delete().then(() => { 
          window.alert("Route " + id + " was deleted"); 
        });
      }
    };

    firebase.auth().onAuthStateChanged((user) => {
      this.user = user;
      this.db.collection('admins').doc(user.uid).get().then((doc) => {
        firebase.analytics().logEvent('Admin Portal Loaded', {
          user_id: user.uid
        });

        if (!doc.data()['admin']) {
          window.location.href = "/";
          return;
        }

        this.isAdmin = true;
        this.setState({user: this.user, isAdmin: this.isAdmin});
      });
    });

    this.routes = [];
    this.columns = [
      {
        field: 'active', headerName: 'Active', width: 100, renderCell: (props) => {
          return (
            <Switch
              checked={props.value}
              onClick={(_) => {
                this.db.collection("routes").doc(props.row.id).update({
                  active: !props.value
                })
              }}
            />
          );
        }
      },
      {
        field: 'id', headerName: 'Route ID', width: 150, renderCell: (props) => {
          // console.log(props);
          return (<>
            <div style={{color: props.color, marginRight: "8px"}}>{props.value}</div>
            <AddRoute cell={props.row} type={"edit"}/>
          </>);
        }
      },
      {field: 'grade', headerName: 'Grade', width: 100},
      {field: 'area', headerName: 'Area', width: 130},
      {
        field: 'status', headerName: 'Status', width: 150, renderCell: (props) => {
          return (
            <Select
              value={props.value}
              onChange={(event) => {
                this.db.collection("routes").doc(props.row.id).update({
                  status: event.target.value
                });
              }}
              style={{width: "100%"}}
            >
              <MenuItem value={"CURRENT"}>CURRENT</MenuItem>
              <MenuItem value={"NEW"}>NEW</MenuItem>
              <MenuItem value={"EXPIRING"}>EXPIRING</MenuItem>
              <MenuItem value={"EXPIRED"}>EXPIRED</MenuItem>
              <MenuItem value={"HIDDEN"}>HIDDEN</MenuItem>
            </Select>
          );
        }
      },
      {field: 'fullColor', headerName: 'Color', width: 130},
      {
        field: 'starRating', headerName: 'Avg Rating', width: 150,
        renderCell: (props) => {
          return (<p>{props.value ?? "None"}</p>);
        }
      },
      {
        field: 'reviewCount', headerName: 'Num Ratings', width: 150,
        renderCell: (props) => {
          return (<p>{props.value ?? "None"}</p>);
        }
      },
      {
        field: 'communityGrade', headerName: 'Avg Grade', width: 150,
        renderCell: (props) => {
          return (<p>{props.value ?? "None"}</p>);
        }
      },
      {
        field: 'userGradeCount', headerName: 'Num Grades', width: 150,
        renderCell: (props) => {
          return (<p>{props.value ?? "None"}</p>);
        }
      },
      {
        field: 'imgURL', headerName: 'Actions', width: 150,
        renderCell: (props) => {
          return (
            <Button variant={"outlined"} color={"secondary"} onClick={
              (val) => {
                this.deleteRoute(props.row.id);
              }
            }>
              Delete
            </Button>
          );
        }
      },
    ];

    this.db.collection("routes").onSnapshot((docs) => {
      var rows = [];
      docs.forEach((doc) => {
        const data = doc.data();
        var tempRow = {};
        tempRow['id'] = doc.id;
        tempRow['grade'] = data['level'];
        tempRow['area'] = data['area'];
        tempRow['imgURL'] = data['imgURL'];
        tempRow['status'] = data['status'];
        tempRow['active'] = data['active'];
        tempRow['reviewCount'] = data['reviewCount'];
        // db.collection("users").doc(user.uid).collection("feedback").doc(doc.id).get().then((userFeedback) => {
        //     tempRow['Status'] = userFeedback.data()['didComplete'];
        // });
        tempRow['starRating'] = undefined;
        // console.log(data['reviewTotal'], data['reviewCount']);
        if (data['reviewCount'] !== undefined && data['reviewTotal'] !== undefined) {
          if (data['reviewCount'] > 0) {
            tempRow['starRating'] = (data['reviewTotal']) / data['reviewCount'];
          }
        }

        tempRow['communityGrade'] = undefined;
        // console.log(data['reviewTotal'], data['reviewCount']);
        if (data['userGradeCount'] !== undefined && data['userGradeTotal'] !== undefined) {
          if (data['userGradeCount'] > 0) {
            tempRow['communityGrade'] = (data['userGradeTotal']) / data['userGradeCount'];
          }
        }

        tempRow['userGradeCount'] = data['userGradeCount'];
        tempRow['color'] = data['color'];
        // console.log(data['color']);
        tempRow['fullColor'] = getFullColorName(data['color']);
        tempRow['type'] = data['type'];
        rows.push(tempRow);
      });
      this.routes = rows;
      this.setState({routes: this.routes});
    });

    this.handleTabChange = (event, newValue) => {
      this.tabValue = newValue;
      this.setState({tabValue: newValue});
    };

  }

  render() {
    if (this.isAdmin) {
      return (
        <Grid container
              justify="center"
              direction="row">
          <Grid item xs={12}>
            <h1>Admin Portal</h1>
          </Grid>
          <AppBar position="static">
            <Tabs value={this.tabValue} onChange={this.handleTabChange} aria-label="simple tabs example">
              <Tab label="Edit Routes" {...a11yProps(0)} />
              <Tab label="View Statistics" {...a11yProps(1)} />
              <Tab label="Ideas?" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={this.tabValue} index={0}>
            <DataGrid
              rows={this.routes}
              columns={this.columns}
              pageSize={50} autoHeight
            />
          </TabPanel>
          <TabPanel value={this.tabValue} index={1}>
            <Statistics rowData={this.routes}/>
          </TabPanel>
          <TabPanel value={this.tabValue} index={2}>
            Ideas?
          </TabPanel>
          <Grid item xs={12} style={{width: '100%'}}>

          </Grid>
          {/* Fab */}
          <AddRoute visable={this.state.editViewVisable} cell={this.state.selectedCell}/>
          {/* Fab */}
        </Grid>
      );
    } else {
      return (<CircularProgress/>);
    }
  }
}

export default AdminPortal;
