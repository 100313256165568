import React from 'react';
import {Link, Route} from 'react-router-dom';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FormatBoldOutlinedIcon from '@material-ui/icons/FormatBoldOutlined';
import FilterHdrIcon from '@material-ui/icons/FilterHdr';
import IconButton from '@material-ui/core/IconButton';

const drawerWidth = 360;


const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerTitle: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0)
  },
  drawerText: {
    textAlign: 'right',
    alignSelf: 'stretch'
  },
}));

export default function RoutesDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Drawer
      className={classes.drawer}
      variant="temporary"
      anchor="left"
      open={props.drawerOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={props.handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
        </IconButton>
      </div>
      <Divider/>
      <h1 className={classes.drawerTitle}>
        Projects
      </h1>
      <Divider/>
      <List>
        {props.currentProjects.map((project, index) => (
          <Route render={({history}) => (
            <ListItem button component={Link} to={"/id/" + project['id']} key={project['id']}
                      onClick={() => {
                        history.push("/id/" + project['id']);
                      }}>
              <ListItemIcon style={{color: project['color']}}>
                {project['type'] === 'Boulder' ? <FormatBoldOutlinedIcon/> : <FilterHdrIcon/>}
              </ListItemIcon>
              <b> <ListItemText primary={project['id']} style={{color: project['color']}}/> </b>
              <ListItemText primary={project['grade']} className={classes.drawerText}
                            style={{marginRight: theme.spacing(1), fontWeight: '200'}}/>
              <ListItemText primary={(project['sends'] ?? 0) + " sends"} className={classes.drawerText}
                            style={{marginRight: theme.spacing(1), fontWeight: '200'}}/>
            </ListItem>
          )} />
        ))}
      </List>
    </Drawer>
  );
}
