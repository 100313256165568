import {Grid, Typography} from "@material-ui/core";
import FilterHdrIcon from "@material-ui/icons/FilterHdr";
import Rating from "@material-ui/lab/Rating";
import * as React from "react";


export default function RatingComponent(props) {
  return (
    <Grid item container xs={12} md={7} justify={"flex-start"}>
      <Grid item xs={12}>
        <Typography mx={2} variant="h2" component="h1" display="inline">{props.routeData.id}</Typography>
      </Grid>
      <Grid item xs={3}>
        <FilterHdrIcon style={{color: props.routeData.color}} display="inline" fontSize="large"/>
      </Grid>
      <Grid item md={9}>
        <Rating readOnly align="right" precision={0.1} value={
          (props.routeData['reviewCount'] && props.routeData['reviewTotal'])
            ? (props.routeData['reviewTotal'] / props.routeData['reviewCount'])
            : null}/>
        <Typography variant="caption" display="inline">({props.routeData['reviewCount'] ?? 0})</Typography>
      </Grid>
    </Grid>
  );
}
