// Import FirebaseAuth and firebase.
import React from 'react';
import Grid from '@material-ui/core/Grid';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from '../Firebase';

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/signedIn',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.PhoneAuthProvider.PROVIDER_ID
  ],
};

function SignIn() {

  firebase.analytics().logEvent('Login Page Hit');

  document.title = `The Knot - Login`;
  return (
    <Grid container justify="center" xs={12}>
      <Grid item>
        <h1>The Knot</h1>
        <h2>Please Login</h2>
      </Grid>
      <Grid item xs={12} justify="center">
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
      </Grid>
    </Grid>
  );
}

export default SignIn;
