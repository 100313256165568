import * as React from 'react';
import {DataGrid} from '@material-ui/data-grid';
import firebase from "../Firebase";
import {Grid, Button, Typography, Icon, Divider, Link, TextField} from '@material-ui/core';
import RouteCard from "./RouteCard";
import getFullColorName from '../funcs/ColorToFullColor';
import FormatBoldOutlinedIcon from "@material-ui/icons/FormatBoldOutlined";
import FilterHdrIcon from "@material-ui/icons/FilterHdr";
import {ChevronRight} from "@material-ui/icons";
import {Autocomplete} from "@material-ui/lab";
import { useHistory } from "react-router-dom";


require('firebase/firestore');

function sortByIdLowHigh(a, b) {
  return (a['id'] ?? 0) - (b['id'] ?? 0);
}

function sortByIdHighLow(a, b) {
  return (a['id'] ?? 0) - (b['id'] ?? 0);
}

function sortByStarRating(a, b) {
  return (b['starRating'] ?? 0) - (a['starRating'] ?? 0);
}

function sortByGrade(a, b) {
  return (b['grade'] ?? 0) - (a['grade'] ?? 0);
}

class ViewClimbs extends React.Component {
  constructor(props) {
    super(props);
    this.db = firebase.firestore();
    this.rows = [];
    document.title = `The Knot - Home`;
    this.state = {
      selectedType: null,
      sortFunction: sortByStarRating
    }

    this.columns = [
      {
        field: 'id', headerName: 'Route ID', width: 120, renderCell: (props) => {
          return (<b>{props.value}</b>);
        }
      },
      {field: 'area', headerName: 'Area', width: 120},
      {field: 'grade', headerName: 'Grade', width: 120},
      {field: 'fullColor', headerName: 'Color', width: 120},
      {field: 'type', headerName: 'Type', width: 120},
      {
        field: 'starRating', headerName: 'Rating', width: 120, renderCell: (props) => {
          return (<p>{props.value ?? "None"}</p>);
        }
      }
    ];

    const setRows = (rows, areas) => {
      this.rows = rows;
      this.areas = areas;
      this.availableRoutes = [];
      for (let row in rows) {
        // console.log(rows[row]);
        this.availableRoutes.push(rows[row]['id']);
      }
      this.setState({rows: this.rows, areas: this.areas, availableRoutes: this.availableRoutes});
    };

    firebase.analytics().logEvent('Home Page Loaded');

    this.db.collection("routes").where('active', '==', true)
      .orderBy("id").onSnapshot((docs) => {
      var rows = [];
      const boulderAreas = new Set();
      const topRopeAreas = new Set();

      docs.forEach((doc) => {
        let data = doc.data();
        var tempRow = {};
        tempRow['id'] = doc.id;
        tempRow['grade'] = data['level'];
        tempRow['area'] = data['area'];
        if (data['type'] === 'Boulder') {
          boulderAreas.add(data['area']);
        } else {
          topRopeAreas.add(data['area']);
        }
        tempRow['imgURL'] = data['imgURL'];
        tempRow['status'] = data['status'];
        // db.collection("users").doc(user.uid).collection("feedback").doc(doc.id).get().then((userFeedback) => {
        //     tempRow['Status'] = userFeedback.data()['didComplete'];
        // });
        tempRow['starRating'] = undefined;
        // console.log(data['reviewTotal'], data['reviewCount']);
        if (data['reviewCount'] !== undefined && data['reviewTotal'] !== undefined) {
          if (data['reviewCount'] > 0) {
            tempRow['starRating'] = (data['reviewTotal']) / data['reviewCount'];
          }
        }

        tempRow['reviewCount'] = data['reviewCount'];
        tempRow['color'] = data['color'];
        // console.log(data['color']);
        tempRow['fullColor'] = getFullColorName(data['color']);
        tempRow['type'] = data['type'];
        rows.push(tempRow);
      });
      setRows(rows, {'Boulder': boulderAreas, 'Top Rope': topRopeAreas});
    });

  }

  render() {
    return (
      <Grid container>
        <Autocomplete
          fullWidth
          renderInput={(params) =>
            <TextField
              {...params}
              size={"small"}
              variant={"outlined"}
              label="Search Routes"
              style={{ marginBottom: "1em", width: "100%", marginTop: "0.5em" }}
            />
          }
          options={this.availableRoutes}
          onChange={(event, newValue) => {
            firebase.analytics().logEvent('User Searched Route', {
              routeID: newValue,
            });
            this.props.history.push("/id/" + newValue);
          }}
        />

        {!this.state.selectedType && (
          <>
            <Grid xs={12} style={{marginBottom: "1em"}}>
              <Typography variant={'h3'} align={'center'}>Choose Route Type</Typography>
            </Grid>
            <Grid xs={6}>
              <Button
                variant={"contained"}
                color={"primary"}
                style={{
                  width: '100%',
                  marginRight: '0.5em',
                  height: '150px'
                }}
                onClick={() => {
                  firebase.analytics().logEvent('User Selected Type', {
                    selectedType: 'Boulder',
                  });
                  this.setState({selectedType: 'Boulder'})
                }}>
                <div>
                  <Icon fontSize={"large"}><FormatBoldOutlinedIcon/></Icon>
                  <Typography variant={"subtitle1"}>Boulder</Typography>
                </div>
              </Button>
            </Grid>
            <Grid xs={6}>
              <Button
                variant={"contained"}
                color={"primary"}
                style={{
                  width: '100%',
                  marginLeft: '0.5em',
                  height: '150px'
                }}
                onClick={() => {
                  firebase.analytics().logEvent('User Selected Type', {
                    selectedType: 'Top Rope',
                  });
                  this.setState({selectedType: 'Top Rope'})
                }}
              >
                <div>
                  <Icon fontSize={"large"}><FilterHdrIcon/></Icon>
                  <Typography variant={"subtitle1"}>Top Rope</Typography>
                </div>

              </Button>
            </Grid>
          </>
        )}

        {(this.state.selectedType) && (this.state.areas) && (!this.state.selectedArea) && (
          <Grid xs={12} style={{marginBottom: "0.5em"}} container>
            <Divider />
            <Grid xs={12}>
              <Typography variant={'h3'} align={'center'} style={{marginBottom: '0.5em'}}>
                <Link color={"secondary"}
                      onClick={() => {
                        this.setState({selectedType: null})
                      }}>
                  {this.state.selectedType}s
                </Link>
                <ChevronRight /> <i>Choose Area</i> </Typography>
            </Grid>
            {Array.from(this.state.areas[this.state.selectedType]).map((row) => {
              return (
                <Grid xs={4}>
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    style={{
                      width: '90%',
                      marginLeft: '0.5em',
                      marginBottom: '0.5em',
                      height: '150px'
                    }}
                    onClick={() => {
                      firebase.analytics().logEvent('User Selected Area', {
                        selectedType: this.state.selectedType,
                        selectedArea: row
                      });
                      this.setState({selectedArea: row})
                    }}
                  >
                    <div>
                      <Typography variant={"subtitle1"}>{row}</Typography>
                    </div>

                  </Button>
              </Grid>
              );
            })}
          </Grid>
        )}

        {(this.state.selectedType) && (this.state.selectedArea) && (
          <Grid xs={12} style={{marginBottom: "0.5em"}} container>
            <Divider />
            <Grid xs={12}>
              <Typography variant={'h3'} align={'center'}>
                <Link
                  color={"secondary"}
                  onClick={() => {
                    this.setState({selectedType: null, selectedArea: null})
                  }}>
                  {this.state.selectedType}s
                </Link>
                <ChevronRight />
                <Link color={"secondary"} onClick={() => {
                  this.setState({selectedArea: null})
                }}>
                  {this.state.selectedArea}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        )}

        {/* OPTION 3 */}
        <Grid container spacing={3}
              justify="center"
              direction="row"
              alignItems="center"
              style={{marginTop: "1em", marginBottom: "1em"}}>
          {this.rows.sort(this.state.sortFunction) && (
            this.rows.map((row) => {
              if (row['type'] === this.state.selectedType && row['area'] === this.state.selectedArea) {
                return (
                  <Grid item xs={6} sm={4} md={3} lg={3}>
                    <RouteCard routeData={row}/>
                  </Grid>
                );
              } else {
                return (<div/>);
              }
            }))
          }
        </Grid>
        {/* OPTION 1 */}

        {/* OPTION 2 */}
        <Grid container spacing={3} justify="center" direction="row" alignItems="flex-start">
          <Grid item xs={12} lg={6} style={{width: '100%'}}>
            <h1 style={{color: "darkgreen"}}>New</h1>
            <DataGrid rows={this.rows.filter((row) => (row['status'] === 'NEW'))} columns={this.columns} pageSize={10}
                      autoHeight
                      onRowSelected={(event) => {
                        let selectedId = event.data.id;
                        firebase.analytics().logEvent('Table Row Selected');
                        this.props.history.push("/id/" + selectedId);
                      }}/>
          </Grid>
          <Grid item xs={12} lg={6} style={{width: '100%'}}>
            <h1 style={{color: "crimson"}}>Expiring</h1>
            <DataGrid rows={this.rows.filter((row) => (row['status'] === 'EXPIRING'))} columns={this.columns}
                      pageSize={10} autoHeight/>
          </Grid>

        </Grid>
        {/* OPTION 2 */}

      </Grid>
    );
  }
}

export default ViewClimbs;
