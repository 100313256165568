import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from "@material-ui/core/Typography";
import firebase from "../../Firebase";

const storage = firebase.storage();

class ImageUploader extends Component {
    constructor(props) {
      super(props);
      this.state = {
        imageName: props.imageName,
        image: null,
        url: "",
        progress: 0
      };
      this.handleURLChanged = props.handleURLChanged;
    }

    handleChange = e => {
      if (e.target.files[0]) {
        const image = e.target.files[0];
        this.setState(() => ({ image }));
      }
    };

    handleUpload = () => {
      const { image, imageName } = this.state;
      const uploadTask = storage.ref(`images/${imageName}`).put(image);
      uploadTask.on(
        "state_changed",
        snapshot => {
          // progress function ...
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          this.setState({ progress });
        },
        error => {
          // Error function ...
          console.log(error);
        },
        () => {
          // complete function ...
          storage
            .ref("images")
            .child(imageName)
            .getDownloadURL()
            .then(url => {
              this.setState({ url });
              this.handleURLChanged(url);
            });
        }
      );
    };

    componentWillReceiveProps(props) {
        this.setState({ imageName: props.name });
    }

    render() {
      return (
        <div className="center">
          <div className="file-field input-field">
            <div className="btn" style={{marginBottom: "1em"}}>
                <Button
                    variant="contained"
                    component="label"
                    color="secondary"
                    >
                    Choose Photo
                        <input type="file" hidden onChange={this.handleChange} />
                </Button>
                <Typography variant="caption" style={{marginLeft: "1em"}}>Upload image to: images/{this.state.imageName}</Typography>
                <Button
                    onClick={this.handleUpload}
                    variant="contained" color="primary"
                    style={{marginLeft: "1em"}}
                    >
                    Upload
                </Button>
            </div>
          </div>

          <div className="row">
            <LinearProgress variant="determinate" value={this.state.progress} />
          </div>
          <img
            style={{marginTop: "1em"}}
            src={this.state.url || ""}
            alt="None Uploaded"
            height="300"
            width="400"
          />
        </div>
      );
    }
  }

  export default ImageUploader;
