import {Box, Grid, Typography} from "@material-ui/core";
import * as React from "react";

export default function RouteInfoComponent(props) {
  return (
    <Grid item container spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start">
      <Grid item xs={3}>
        <Box component="fieldset" mb={1} mt={2} borderColor="transparent">
          <Typography component="legend">Grade</Typography>
          <Typography variant="h6" display="inline">{props.routeData.level}</Typography>
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Box component="fieldset" mb={1} mt={2} borderColor="transparent">
          <Typography component="legend">Type</Typography>
          <Typography variant="h6" display="inline">{props.routeData.type}</Typography>
        </Box>
      </Grid>

      <Grid item xs={5}>
        <Box component="fieldset" mb={1} mt={2} borderColor="transparent">
          <Typography component="legend">Status</Typography>
          {(props.routeData['status'] === 'NEW') && (
            <Typography variant="h6" style={{color: "green"}} display="inline">NEW</Typography>
          )}
          {(props.routeData['status'] === 'EXPIRING') && (
            <Typography variant="h6" style={{color: "red"}} display="inline">EXPIRING</Typography>
          )}
          {(props.routeData['status'] === 'CURRENT') && (
            <Typography variant="h6" style={{color: "grey"}} display="inline">CURRENT</Typography>
          )}
        </Box>
      </Grid>

    </Grid>
  );
}
